import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const SmartStepSystem = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginBottom: '3%' }}>
                <Image
                    style={{
                        width: '500px',
                    }}
                    src={require('../assets/smartstepsystem-logo.png')}
                />
            </Row>
            <Row style={{ marginTop: 15 }}>
            <p>Track user movement and fitness data across iOS and Android devices.</p>
            <div>
                <h5>Key Features</h5>
                <ul>
                    <li>
                        <b>Real-Time Step Tracking</b>
                        <p>Collect valuable metrics like steps taken, distance, pace, cadence, and floors ascended or descended* with a powerful tracking subsystem. Smart Step System helps you create engaging fitness experiences that respond instantly to users’ movements.</p>
                    </li>

                    <li>
                        <b>Pre-Built Overlay Widgets</b>
                        <p>Quickly integrate movement data displays with an array of pre-built widgets. From step counters and cadence indicators to distance and elevation widgets, each overlay provides flexible, informative visuals that make data accessible and easy to read.</p>
                    </li>

                    <li>
                        <b>Automated Permission Management</b>
                        <p>Smart Step System automatically handles permission requests, giving users a streamlined experience across platforms with minimal configuration on your end.</p>
                    </li>
                </ul>
                <p>Whether you're building a fitness app, adding activity insights, or creating an interactive game, Smart Step System offers everything you need to keep users informed and motivated.</p>
                <p style={{ fontSize: '0.75em', fontStyle: 'italic'}}>
                    *Average active pace and floors traveled aren't available on Android devices due to sensor limitations. On Android devices, distance, current pace, and cadence are calculated from available sensor data. See documentation for details.
                </p>

            </div>
                <p>
                    <a href='https://www.fab.com/'>Smart Step System - FAB Marketplace</a>
                </p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row',  display: 'inline-block'}}>
                <h3>Related Files</h3>
                <a href='https://www.herocode.us/smartstepsystem/smartstepsystem.pdf'>Smart Step System Documentation</a>
            </Row>
        </Container>
    )
}

export default SmartStepSystem