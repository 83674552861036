import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const IosMotionToolkit = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '400px',
                    }}
                    src={require('../assets/iosmotiontoolkit-logo.png')}
                />
            </Row>
            <Row style={{ marginTop: 15 }}>
            <p>Extend Apple's Core Motion Framework with iOS Motion Toolkit</p>
            <div>
                <h5>Key Features</h5>
                <ul>
                    <li>
                        <b>Real-Time Motion Data</b>
                        <p>Access real-time data from iOS Core Motion with a built-in motion subsystem. Quickly tap into essential metrics like Gravity, Magnetic Field, and Acceleration. With this, you can dynamically react to motion and create immersive, motion-driven experiences with minimal setup.</p>
                    </li>

                    <li>
                        <b>Customizable Overlay Widgets</b>
                        <p>Easily add motion data overlays to your UI. Widgets include Gravity Field Overlays, Magnetic Field Indicators, Acceleration Meters, and G-Force displays. Each is designed to enhance interactivity and bring real-time data visualization to your game.</p>
                    </li>

                    <li>
                        <b>Advanced Widgets</b>
                        <p>
                            Unlock powerful features with specialized widgets like:
                            <ul>
                                <li><b>Compass Widget</b>: Show device orientation and heading based on magnetic north.</li>
                                <li><b>Gravitational Tilt Widget</b>: Accurately detect pitch and roll relative to gravity—perfect for AR/VR experiences.</li>
                                <li><b>Shake Detector Widget</b>: Recognize when your device is shaken and display a dynamic message. This feature is ideal for creating engaging interactions and feedback.</li>
                            </ul>
                        </p>
                    </li>
                </ul>
                <p>With an extensive collection of pre-made widgets, iOS Motion Toolkit gives you everything needed to quickly integrate motion data into your Unreal Engine projects.</p>
            </div>
                <p>
                    <a href='https://www.fab.com/listings/1cc8abc2-71c9-470b-97fc-6334571d26c7'>iOS Motion Toolkit - FAB Marketplace</a>
                </p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row',  display: 'inline-block'}}>
                <h3>Related Files</h3>
                <a href='https://www.herocode.us/iosmotiontoolkit/iosmotiontoolkit.pdf'>iOS Motion Toolkit Documentation</a>
            </Row>
        </Container>
    )
}

export default IosMotionToolkit