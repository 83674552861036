import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const MobileHapticFeedback = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '600px',
                    }}
                    src={require('../assets/mobilehapticfeedback-logo.png')}
                />
            </Row>
            <Row style={{ marginTop: 15 }}>
                <p>Mobile Haptic Feedback is a lightweight and powerful plugin designed to deliver precise haptic feedback on mobile devices. This plugin integrates smoothly with both iOS and Android platforms, ensuring your users feel every interaction through responsive, platform-optimized vibrations.</p>
                <div>
                    <p>Key Features:</p>
                    <ul>
                        <li><strong>Mobile Compatibility:</strong> Reliable haptic feedback for iOS and Android.</li>
                        <li><strong>Blueprint Accessible:</strong> Initiate haptic feedback easily with Blueprint nodes—no coding required!</li>
                        <li><strong>Customizable Intensity:</strong> Adjust feedback strength to fit various gameplay scenarios.</li>
                        <li><strong>Low Performance Impact:</strong> Optimized for smooth feedback.</li>
                        <li><strong>Quick Setup:</strong> Simply create a node, set feedback options, and you're ready to go.</li>
                    </ul>
                </div>
                <p>
                    <a href='https://www.fab.com/listings/8b7d9885-a0d0-4eb5-bfd9-157a5d1bfbec'>Mobile Haptic Feedback - FAB Marketplace</a>
                </p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row',  display: 'inline-block'}}>
                <h3>Related Files</h3>
                <a href='https://www.herocode.us/mobilehapticfeedback/mobilehapticfeedback.pdf'>Mobile Haptic Feedback Documentation</a>
            </Row>
        </Container>
    )
}

export default MobileHapticFeedback