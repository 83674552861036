import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const MobileStatusToolkit = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginBottom: '3%' }}>
                <Image
                    style={{
                        width: '500px',
                    }}
                    src={require('../assets/mobilestatustoolkit-logo.png')}
                />
            </Row>
            <Row style={{ marginTop: 15 }}>
            <p>This plugin provides easy access to essential device status data, allowing you to track battery levels, network status, storage space, and more. Blueprint-ready and compatible with both Android and iOS, it ensures you can quickly integrate the data you need with minimal setup.</p>
            <div>
                <h5>Key Methods & Features</h5>
                <br/>
                <p>
                    <p>Battery Monitoring</p>
                    <ul>
                        <li><b>IsDeviceCharging: </b>Check if the device is charging.</li>
                        <li><b>GetBatteryStatus: </b>Get the current battery status (charging, discharging, etc.).</li>
                        <li><b>GetBatteryLevel: </b>Get the current battery percentage, enabling performance optimization or user notifications when power is low.</li>
                    </ul>

                </p>
                <p>
                    <p>Network Status</p>
                    <ul>
                        <li><b>GetNetworkStatus: </b>Determine the active network type (e.g., Cellular, Wi-Fi, Ethernet).</li>
                        <li><b>GetNetworkTechnology: </b>Identify the type of network technology in use (e.g., 3G, 4G, 5G, LTE).</li>
                    </ul>

                </p>
                <p>
                    <p>Storage Information</p>
                    <ul>
                        <li><b>GetDiskInfo: </b>Access key storage details, including total space and free space, to help users manage their devices more effectively.</li>
                    </ul>

                </p>
                <p>
                    <p>Device Info</p>
                    <ul>
                        <li><b>GetDeviceInfo: </b>Get detailed information about the device, including the system name and version, model, manufacturer, low power mode status, physical memory, processor core count, and more!</li>
                    </ul>

                </p>
                <p>Plugin includes a demo scene and widget to help you get started quickly, making it easy to integrate and customize device status features for your app.</p>
            </div>
                <p>
                    <a href='https://www.fab.com/'>Mobile Status Toolkit - FAB Marketplace</a>
                </p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row',  display: 'inline-block'}}>
                <h3>Related Files</h3>
                <a href='https://www.herocode.us/mobilestatustoolkit/mobilestatustoolkit.pdf'>Mobile Status Toolkit Documentation</a>
            </Row>
        </Container>
    )
}

export default MobileStatusToolkit