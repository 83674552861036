import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const MobileAlertToolkit = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '600px',
                    }}
                    src={require('../assets/mobilealerttoolkit-logo.png')}
                />
            </Row>
            <Row>
                <div style={{ marginTop: 25 }}>
                    <p>
                        The Mobile Alert Toolkit is a must-have for developers looking to integrate sleek, customizable, and native alerts into their mobile apps for iOS and Android. This powerful plugin provides a Blueprint node that allows you to create alerts with a range of flexible options, ensuring a smooth user experience across both platforms.
                    </p>
                    <div>
                        <h5>Key Features</h5>
                        <ul>
                            <li><b>Customizable Alerts</b>: Set the title, content, and button texts for accept and cancel actions.</li>
                            <br/>
                            <li><b>Hide Cancel Button</b>: Option to hide the cancel button for single-choice alerts.</li>
                            <br/>
                            <li><b>Destructive Actions</b>: Customize the color of the accept button to reflect destructive actions.</li>
                            <br/>
                            <li><b>Rich Text Support</b>: Fully customizable rich text on Android, with iOS supporting clickable hyperlinks for interactive alerts.</li>
                            <br/>
                            <li><b>Cross-Platform Support</b>: Compatible with both iOS and Android devices.</li>
                            <br/>
                            <li><b>Easy to Use</b>: Drag-and-drop integration via Blueprint, no coding required!</li>
                            <br/>
                            <li><b>Demo Scene Included</b>: Quickly get started with a demo scene included in the plugin.</li>
                        </ul>
                    </div>
                    <p>With Mobile Alert Toolkit, you have the power to create professional, native-looking alerts that enhance your mobile app's user experience!</p>
                    <p>
                        <a href='https://www.fab.com/listings/d9b9898c-f696-49d2-bc97-3d336eeebfbd'>Mobile Alert Toolkit - FAB Marketplace</a>
                    </p>
                </div>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row',  display: 'inline-block'}}>
                <h3>Related Files</h3>
                <a href='https://www.herocode.us/mobilealerttoolkit/mobilealerttoolkit.pdf'>Mobile Alert Toolkit Documentation</a>
            </Row>
        </Container>
    )
}

export default MobileAlertToolkit