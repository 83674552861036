import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const IosNfcKit = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '400px',
                    }}
                    src={require('../assets/iosnfckit-logo.png')}
                />
            </Row>
            <Row style={{ marginTop: 15 }}>
            <p>Integrate NFC tag reading and writing into your iOS projects. Read, write, and manage NFC NDEF tags, handle payloads, and use a wide range of Blueprint utilities to simplify your workflow. Perfect for interactive experiences, games, and utility apps.</p>
            <div>
                <h5>Key Features</h5>
                <ul>
                    <li><b>NFC Tag Read & Write</b>: Easily read and write NFC NDEF tags using Blueprints.</li>
                    <li><b>Minimal Setup Required</b>: Quickly integrate NFC features with clear documentation for easy setup.</li>
                    <li><b>Blueprint Utilities</b>: Simplify NFC operations, such as retrieving formatted content based on record type, converting bytes to strings, and more!</li>
                    <li><b>Optimized Performance</b>: Lightweight and optimized to keep your app running smoothly.</li>
                    <li><b>Demo Scene Included</b>: Demo scene provided to help you get started quickly and understand how to implement NFC functionality.</li>
                </ul>
            </div>
                <p>
                    <a href='https://www.fab.com/listings/f4ffe607-be7d-40ff-8cfc-2f7adccda01a'>iOS NFC Kit - FAB Marketplace</a>
                </p>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row',  display: 'inline-block'}}>
                <h3>Related Files</h3>
                <a href='https://www.herocode.us/iosnfckit/iosnfckit.pdf'>iOS NFC Kit Documentation</a>
            </Row>
        </Container>
    )
}

export default IosNfcKit